import React from "react"
import Seo from "../components/Seo"

const PrivacyPolicy = () => {
  return (
    <>
      <Seo title="Privacy policy" />
      <object
        type="application/pdf"
        data={process.env.GATSBY_PRIVACY_POLICY_URL}
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <embed
          src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${process.env.GATSBY_PRIVACY_POLICY_URL}`}
          style={{ width: "100vw", height: "100vh" }}
        />
      </object>
    </>
  )
}
export default PrivacyPolicy
